import styled from 'styled-components';
import { Theme } from '../../../../main/style/theme';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 170px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);

  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 0 10px;
  }
`;

export const FilterItems = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;

  p {
    width: 100px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    p {
      display: none;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 70%;

  gap: 20px;
  padding: 20px;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  box-sizing: border-box;
`;

export const Card = styled.div<{ colorType: keyof Theme['styles']['colors'] }>`
  width: 30%;
  height: auto;
  padding: 20px;
  border-radius: 16px;
  gap: 20px;

  position: relative;
  display: flex;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};

  border: 1px solid
    ${({ colorType, theme }) =>
      theme.styles.colors[colorType]?.medium
        ? theme.styles.colors[colorType].medium
        : '#17749c'}55;

  @media ${({ theme }) => theme.devices.laptop} {
    width: 48%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    height: auto;

    align-items: center;
    justify-content: center;
  }
`;

export const Badge = styled.div<{ colorType: keyof Theme['styles']['colors'] }>`
  padding: 5px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: ${({ theme }) => theme.styles.spacing.short};

  display: flex;
  align-items: center;

  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: lighter;
  font-size: ${({ theme }) => theme.styles.font.small};

  background-color: ${({ colorType, theme }) =>
    theme.styles.colors[colorType]?.medium
      ? theme.styles.colors[colorType].medium
      : '#000000'};

  color: ${({ theme }) => theme.styles.colors.white.medium};

  svg {
    margin-right: 5px;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    top: 10px;
    right: 10px;
  }
`;

export const CardBody = styled.div`
  width: 100%;
`;

export const CardFooter = styled.div``;

export const Footer = styled.div`
  width: 100%;
  height: 80px;

  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;

  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
`;
