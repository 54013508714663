import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 80px;
`;

export const Form = styled.form`
  width: 100%;
  height: auto;
  overflow: hidden;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;
`;

export const Divisor = styled.div<{ width: string }>`
  width: 100%;
  height: ${({ width }) => width};

  padding: 20px;
  gap: 20px;

  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    padding: 10px;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  padding-right: 10px;

  @media ${({ theme }) => theme.devices.mobileL} {
    height: auto;
    padding-right: 0px;
  }
`;
