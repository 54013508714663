import React, { useState } from 'react';
import { RiInboxArchiveFill } from 'react-icons/ri';
import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Input from '../../../components/input';
import Switch from '../../../components/switch';
import TextArea from '../../../components/text-area';
import { Container, Header, Form, Divisor, Box } from './styled';
import { createNotificationSchema } from '../../../../validation/forms';

import Select from '../../../components/select';
import Paragraph from '../../../components/paragraph';
import FiltersContainer from './filter';
import { createNotification } from '../../../../data/usecases/notifications';

const CreateNotification: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({
    first: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createNotificationSchema),
  });

  const onSubmit = async (value: any) => {
    setLoading(true);

    if (!steps.first) {
      toast.error('Selecione pelo menos um filtro');
      setLoading(false);
      return;
    }

    if (!value.configs.user.special && !value.configs.user.status) {
      toast.error('Selecione pelo menos um filtro de status ou outra condição');
      setLoading(false);
      return;
    }

    dispatch(
      createNotification(
        {
          ...value,
          hour: `${value.hour}:00`,
        },
        history
      )
    );

    setLoading(false);
  };

  const onChange = async (event: any) => {
    setSteps((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Container>
      <Header>
        <Head title="Cadastro de notificação" icon={<RiInboxArchiveFill />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="90%">
          <Box>
            <Paragraph align="left" fontSize="medium" label="Dados" />

            <Input
              label="Nome"
              type="text"
              {...register('name')}
              error={errors.name}
            />

            <Input
              label="Dia"
              type="date"
              {...register('day')}
              error={errors.day}
            />

            <span>
              Os valores permitidos são no intervalo de 5 minutos (exemplo:
              13h05)
            </span>
            <Input
              label="Hora"
              type="time"
              {...register('hour')}
              error={errors.hour}
              min="00:00"
              max="23:59"
              step={300}
            />

            <Switch
              label="É um envio diário?"
              {...register('recurrence')}
              error={errors.recurrence}
              enabled
            />

            <Input
              label="Título da mensagem"
              type="text"
              placeholder="Olá, gente querida!"
              {...register('title')}
              error={errors.title}
            />

            <TextArea
              label="Corpo da mensagem"
              {...register('body')}
              error={errors.body}
            />
          </Box>

          <Box>
            <Paragraph align="left" fontSize="medium" label="Filtros" />
            <Paragraph align="left" fontSize="small">
              Selecione uma opção abaixo para iniciar o fluxo de filtros
            </Paragraph>

            <Select
              label="Selecione o primeiro filtro deseja"
              name="first"
              items={[
                { externalId: null, name: 'Selecione uma opção' },
                { externalId: 'status', name: 'Por status' },
                { externalId: 'special', name: 'Outra condição' },
              ]}
              onChange={onChange}
            />

            <FiltersContainer
              state={steps}
              register={register}
              errors={errors}
              setValue={setValue}
            />
          </Box>
        </Divisor>

        <Divisor width="10%">
          <Box>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Cadastrar
            </Button>
          </Box>
        </Divisor>
      </Form>
    </Container>
  );
};

export default CreateNotification;
