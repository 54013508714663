import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.styles.colors.gray.light}33;

  position: relative;
`;

export const Menu = styled.div`
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 100;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.styles.colors.blue.medium};

  left: -45px;
  top: -10px;

  svg {
    font-size: ${({ theme }) => theme.styles.font.medium};
    color: ${({ theme }) => theme.styles.colors.white.normal};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    width: 80px;
    height: 80px;

    left: -35px;
    padding-right: 18px;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 70px;
    height: 70px;

    left: -20px;
    padding-right: 15px;
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.styles.colors.blue.light}44;
`;

export const ChildrenContainer = styled.div`
  max-width: 1600px;
  width: 100%;
  min-height: 100%;
  height: auto;

  @media ${({ theme }) => theme.devices.laptop} {
    width: 100vw;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 100vw;
  }
`;
