import React, { useEffect } from 'react';
import { Badge, Skeleton, Stack } from '@chakra-ui/react';
import { FaTicketAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import { listTickets } from '../../../../data/usecases/tickets/list-all-tickets';

import {
  Container,
  Body,
  Card,
  Image,
  Title,
  PlatformContainer,
} from './styled';

import Paragraph from '../../../components/paragraph';
import * as Button from '../../../components/button';
import Head from '../../../components/header';

const Tickets: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const tickets = useAppSelector((state) => state.tickets);

  const pushToDetails = (id: string, course: any) => {
    history.push(`/tickets/detalhes/${id}`, course);
  };

  useEffect(() => {
    dispatch(listTickets());
  }, []);

  return (
    <Container>
      <Head title="Ingressos" icon={<FaTicketAlt />} />
      <Body>
        {tickets.data ? (
          tickets.data.map((course: any, index: number) => (
            <Card key={index}>
              <Image alt={course.course.name} src={course.course.title} />

              <PlatformContainer>
                <Title>{course.course.name}</Title>
                {course.platforms.map((platform: any, key: number) => (
                  <div key={key}>
                    <Paragraph
                      align="left"
                      fontSize="small"
                      label={`Produto ${key + 1}:`}
                    >
                      {platform.name}{' '}
                      <Badge colorScheme="green">{platform.platform}</Badge>
                    </Paragraph>
                  </div>
                ))}

                <Button.Primary
                  backgroundColor="blue"
                  color="white"
                  label="Acessar"
                  type="button"
                  onClick={() =>
                    pushToDetails(course.course.external_id, course)
                  }
                />
              </PlatformContainer>
            </Card>
          ))
        ) : (
          <Stack spacing="20px">
            {[1, 2, 3, 4].map((_, index) => (
              <Skeleton
                borderRadius="md"
                key={index}
                startColor="blue.500"
                endColor="blue.800"
                height="20px"
              />
            ))}
          </Stack>
        )}
      </Body>
    </Container>
  );
};

export default Tickets;
