import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MdEventAvailable } from 'react-icons/md';
import { Box, Stack, StackDivider, useDisclosure } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Paragraph from '../../../components/paragraph';
import {
  Container,
  Header,
  Grid,
  FlexContainer,
  Section,
  Img,
  HeaderItems,
  ButtonContainer,
} from './styled';

import { listEventsById } from '../../../../data/usecases/events/list-by-id-events';
import { formatDate } from '../../../../utils';
import * as Button from '../../../components/button';
import ModalComponent from './modal';
import ModalVideo from './video';
import { deleteEvents } from '../../../../data/usecases/events/delete-events';

export type Props = {
  match: {
    params: {
      id: string;
    };
  };
};

const Events: React.FC<Props> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenVideo,
    onOpen: onOpenVideo,
    onClose: onCloseVideo,
  } = useDisclosure();
  const events = useAppSelector((state) => state.events);

  const listEvents = () => {
    dispatch(
      listEventsById({
        id: props.match.params.id,
      })
    );
  };

  const removeEvents = () => {
    dispatch(
      deleteEvents(
        {
          id: props.match.params.id,
        },
        history
      )
    );
  };

  const pushToUpdate = () => {
    history.push(`/eventos/atualizar/${props.match.params.id}`);
  };

  useEffect(() => {
    listEvents();
  }, []);

  return (
    <Container>
      <Head title="Detalhes do evento" icon={<MdEventAvailable />} />

      <Grid>
        {events.events && (
          <Header>
            <Img src={events.events.course.title} alt="Andando no profético" />
            <Img src={events.events.image.url} alt="Andando no profético" />

            <HeaderItems>
              <Paragraph fontSize="default" align="left">
                {events.events.course.description}
              </Paragraph>

              <Paragraph fontSize="default" label="Data" align="left">
                {events.events.course.date}
              </Paragraph>

              <Paragraph fontSize="default" label="Local" align="left">
                {events.events.course.local}
              </Paragraph>

              <a
                href={events.events.course.link}
                target="_blank"
                rel="noreferrer"
              >
                <Button.Primary
                  backgroundColor="blue"
                  color="white"
                  label="Página de acesso"
                  type="button"
                />
              </a>
            </HeaderItems>
          </Header>
        )}

        <FlexContainer>
          <Section>
            {events.events && (
              <>
                <h2>Informações</h2>

                <Stack divider={<StackDivider />} spacing="4">
                  <Box>
                    <Paragraph
                      fontSize="default"
                      label="Disponível em"
                      align="left"
                    >
                      {events.events?.course?.active
                        ? 'Eventos'
                        : 'ProphetFlix'}
                    </Paragraph>
                  </Box>
                  <Box>
                    <Paragraph
                      fontSize="default"
                      label="Tipo de evento"
                      align="left"
                    >
                      {events.events?.type?.type}
                    </Paragraph>
                  </Box>

                  {events.events?.course?.video && (
                    <Box>
                      <Paragraph
                        fontSize="default"
                        label="Vídeo"
                        align="left"
                      />

                      <Button.Primary
                        backgroundColor="blue"
                        color="white"
                        label="Visualizar"
                        type="button"
                        onClick={onOpenVideo}
                      />
                    </Box>
                  )}

                  <Box>
                    <Paragraph
                      fontSize="default"
                      label="Data de criação"
                      align="left"
                    >
                      {formatDate(events.events?.course?.created_at)}
                    </Paragraph>
                  </Box>
                </Stack>
              </>
            )}
          </Section>

          <Section>
            {events.events && (
              <ButtonContainer>
                <h2>Ações</h2>

                <Button.Primary
                  backgroundColor="orange"
                  color="black"
                  label="Atualizar"
                  type="button"
                  onClick={pushToUpdate}
                />
                <Button.Primary
                  backgroundColor="red"
                  color="white"
                  label="Deletar"
                  type="button"
                  onClick={onOpenDelete}
                />
              </ButtonContainer>
            )}
          </Section>
        </FlexContainer>
      </Grid>

      <ModalComponent
        removeEvents={removeEvents}
        isOpen={isOpenDelete}
        isLoading={events.isFetch}
        onClose={onCloseDelete}
      />

      {events?.events?.course && (
        <ModalVideo
          isOpen={isOpenVideo}
          video={events.events.course.video}
          onClose={onCloseVideo}
        />
      )}
    </Container>
  );
};

export default Events;
