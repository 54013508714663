import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 170px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);

  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 0 10px;
  }
`;

export const FilterItems = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;

  p {
    width: 100px;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    p {
      display: none;
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;

  gap: 20px;
  padding: 20px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
`;

export const Card = styled.div`
  width: 25%;
  min-height: 400px;
  height: auto;
  padding: 20px;
  border-radius: 16px;
  gap: 10px;
  display: flex;
  align-items: space-around;
  justify-content: space-around;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    height: auto;

    align-items: center;
    justify-content: center;
  }
`;

export const Paragraph = styled.p`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: left;
  font-weight: 600;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 22px;
`;

export const CardBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const CardFooter = styled.div``;

export const Image = styled.img`
  width: 100%;
  max-height: 250px;
  object-fit: contain;

  border-radius: 16px;

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 60%;
  }
`;

export const LinkText = styled.a`
  font-size: 16px;
  font-weight: bolder;
  color: blue;
  text-decoration: underline;
`;

export const AliasText = styled.h2`
  font-size: 14px;
  color: gray;
`;

export const TextContent = styled.text`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  word-wrap: break-word;
  overflow-wrap: break-word;

  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  max-width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  height: 80px;

  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;

  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
`;
