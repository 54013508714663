import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 100px;
`;

export const Form = styled.form`
  width: 100%;
  height: auto;
  overflow: hidden;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;
`;

export const Divisor = styled.div<{ width: string; scroll: boolean }>`
  width: 100%;
  height: ${({ width }) => width};

  padding: 20px;
  gap: 20px;

  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'unset')};
    padding: 10px;
  }
`;

export const Box = styled.div<{ scroll: boolean }>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  box-sizing: border-box;

  overflow-y: ${({ scroll }) => (scroll ? 'scroll' : 'unset')};

  @media ${({ theme }) => theme.devices.mobileL} {
    height: 100%;
    overflow-y: unset;
  }
`;

export const Paragraph = styled.p`
  line-height: 1.5em;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: lighter;

  font-size: ${({ theme }) => theme.styles.font.default};
`;
