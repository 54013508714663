import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif ;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888888AA;
      border-radius: 16px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  html{
    font-size: 16px;
    box-sizing: border-box;
  }

`;

export default Global;
