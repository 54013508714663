import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 55mm;
  height: 13mm;

  position: relative;
`;

export const QrCodeContainer = styled.div`
  width: 30px;
  height: 30px;

  position: absolute;
  top: 5px;
  right: 5px;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const PlatformContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  h3 {
    font-size: 0.9rem;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    font-weight: 900;
  }

  p {
    font-size: 0.5rem;
    font-weight: 900;
  }

  div {
    width: 100%;
  }
`;
