import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 80px;
`;

export const Body = styled.div`
  width: 100%;
  height: auto;

  gap: 20px;
  padding: 20px;
`;

export const Footer = styled.div`
  width: 100%;
  height: 100px;

  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;

  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
`;
