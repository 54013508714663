import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 20px;

  h2 {
    line-height: 1.5em;
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: bold;
    margin: 10px 0;

    font-size: ${({ theme }) => theme.styles.font.medium};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    justify-content: flex-start;
  }
`;

export const Header = styled.div`
  height: 48%;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;
  padding: 20px;
  gap: 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  flex-direction: row;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
`;

export const HeaderItems = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: 1.3rem;
  }
`;

export const Img = styled.img`
  min-width: 200px;
  max-width: 300px;
  max-height: 350px;

  object-fit: contain;

  @media ${({ theme }) => theme.devices.mobileL} {
    min-width: 50px;
  }
`;

export const FlexContainer = styled.div`
  width: 100%;
  height: 50%;

  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
    height: auto;
    gap: 20px;
    margin-top: 20px;
  }
`;

export const Section = styled.div`
  width: 49%;
  height: 98%;

  padding: 20px;
  box-sizing: border-box;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    height: auto;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
