import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useCallbackRef,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';

import { CardContainer, QrCodeContainer, PlatformContainer } from './styled';

export type ModelDto = {
  isOpen: boolean;
  onClose: () => void;
  clear: () => void;
  ticket: any;
  isLoading: boolean;
};

const DrawerComponent: React.FC<ModelDto> = ({
  isOpen,
  onClose,
  clear,
  ticket,
  isLoading,
}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(ticket.participant?.name);
  }, [ticket.participant?.name]);

  const onCloseModal = () => {
    clear();
    onClose();
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({ contentRef });

  const usePrint = useCallbackRef(() => {
    handlePrint();
  });

  return (
    <Modal onClose={onCloseModal} size="6xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Etiqueta</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Spinner colorScheme="blue" />
          ) : (
            <>
              <CardContainer ref={contentRef}>
                <PlatformContainer>
                  <h3>{value}</h3>
                  <p className="name_platform">{ticket.platform?.name}</p>
                </PlatformContainer>
              </CardContainer>

              <hr
                style={{
                  marginTop: '10px',
                }}
              />

              <h3
                style={{
                  marginTop: '10px',
                  fontWeight: 500,
                }}
              >
                Altere o nome da pessoa:
              </h3>
              <input
                style={{
                  width: '100%',
                  padding: '5px',
                  border: '1px solid #00000055',
                  borderRadius: 8,
                  cursor: 'pointer',
                }}
                type="text"
                value={value}
                onChange={(event) => setValue(() => event.target.value)}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" onClick={usePrint}>
            Imprimir
          </Button>
          <Button marginLeft={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DrawerComponent;
