import React, { useState, useCallback, useEffect } from 'react';
import { RiInboxArchiveFill } from 'react-icons/ri';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { Button, IconButton, useDisclosure } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Input from '../../../components/input';
import Select from '../../../components/select';
import Switch from '../../../components/switch';
import TextArea from '../../../components/text-area';
import {
  Container,
  Header,
  Form,
  Divisor,
  Box,
  File,
  TypeContainer,
} from './styled';
import { convertIntoBase64 } from '../../../../utils/file';
import { createProductSchema } from '../../../../validation/forms/products/create-product';
import { listProductType } from '../../../../data/usecases/product-type/list-all-product-type';
import ModalComponent from './modal';
import { createProductType } from '../../../../data/usecases/product-type/create-product-type';
import { createImage } from '../../../../data/usecases/image/create-image';
import { createProduct } from '../../../../data/usecases/products/create-product';

const CreateProduct: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const productType = useAppSelector((state) => state.productType) as any;
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [productTypeName, setProductType] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createProductSchema),
  });

  const onFile = useCallback(
    async (file: React.ChangeEvent<HTMLInputElement>) => {
      const base64 = await convertIntoBase64(file.target.files);
      setValue('image', file.target.files?.[0]);
      setImage(base64 as string);
    },
    [image]
  );

  const onCreateProduct = () => {
    dispatch(createProductType({ name: productTypeName }));
    onClose();
  };

  const onSubmit = async (value: any) => {
    setLoading(true);

    const url = await createImage(value.image);

    if (!url) {
      return;
    }

    dispatch(
      createProduct(
        {
          ...value,
          image: {
            url,
          },
        },
        history
      )
    );
  };

  useEffect(() => {
    dispatch(listProductType());
  }, []);

  return (
    <Container>
      <Header>
        <Head title="Cadastro de produto" icon={<RiInboxArchiveFill />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="90%">
          <Box>
            <File>
              <label htmlFor="image">
                {image ? (
                  <img src={image} alt="Logo do produto" />
                ) : (
                  <FaCloudUploadAlt />
                )}

                <input
                  id="image"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  {...register('image')}
                  onChange={onFile}
                />
              </label>
            </File>
          </Box>

          <Box>
            <Input
              label="Nome"
              type="text"
              {...register('name')}
              error={errors.name}
            />

            <TextArea
              label="Descrição"
              {...register('description')}
              error={errors.description}
            />

            <Input
              label="Link de acesso"
              type="text"
              placeholder="https://..."
              {...register('link')}
              error={errors.link}
            />
            <Input
              label="Preço de venda"
              type="number"
              placeholder="Colocar somente o número (exemplo: 5990 para R$59,90)"
              {...register('value')}
              error={errors.value}
            />

            <TypeContainer>
              <Select
                label="Tipo"
                {...register('product_type_id')}
                error={errors.product_type_id}
                items={productType?.data ? productType.data : []}
              />
              <IconButton
                variant="outline"
                colorScheme="blue"
                aria-label="Add"
                fontSize="20px"
                icon={<MdAdd />}
                onClick={onOpen}
              />
            </TypeContainer>

            <Switch
              label="Deseja habilitar no aplicativo?"
              {...register('enabled')}
              error={errors.enabled}
              enabled
            />
          </Box>
        </Divisor>

        <Divisor width="10%">
          <Box>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Cadastrar
            </Button>
          </Box>
        </Divisor>
      </Form>

      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        setProductType={setProductType}
        onCreateProduct={onCreateProduct}
      />
    </Container>
  );
};

export default CreateProduct;
