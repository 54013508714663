import React, { useState, useCallback, useEffect } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { Button } from '@chakra-ui/react';
import { MdEventAvailable } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Input from '../../../components/input';
import Select from '../../../components/select';
import TextArea from '../../../components/text-area';
import {
  Container,
  Header,
  Form,
  Divisor,
  Box,
  File,
  TypeContainer,
  BoxFile,
} from './styled';
import { convertIntoBase64 } from '../../../../utils/file';
import { createCourseSchema } from '../../../../validation/forms/events/create-events';
import { listEventsType } from '../../../../data/usecases/events-type/list-all-events-type';
import { createImage } from '../../../../data/usecases/image/create-image';
import { createEvents } from '../../../../data/usecases/events/create-events';
import Radio from '../../../components/radio';

const CreateEvents: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const eventsType = useAppSelector((state) => state.eventsType) as any;
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createCourseSchema),
  });

  const onFile = useCallback(
    async (file: React.ChangeEvent<HTMLInputElement>) => {
      const base64 = await convertIntoBase64(file.target.files);
      setValue(file.target.name, file.target.files?.[0]);
      if (file.target.name === 'title') {
        setTitle(base64 as string);
      } else {
        setImage(base64 as string);
      }
    },
    [image, title]
  );

  const onSubmit = async (value: any) => {
    setLoading(true);

    const url = await createImage(value.image);
    const urlTitle = await createImage(value.title);

    if (!url || !urlTitle) {
      return;
    }

    dispatch(
      createEvents(
        {
          ...value,
          title: urlTitle,
          active: value.active === 'event',
          image: {
            url,
          },
        },
        history
      )
    );

    setLoading(false);
  };

  useEffect(() => {
    dispatch(listEventsType());
  }, []);

  return (
    <Container>
      <Header>
        <Head title="Cadastro de eventos" icon={<MdEventAvailable />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="90%">
          <Box>
            <BoxFile>
              <p>Título do evento</p>
              <File>
                <label htmlFor="title">
                  {title ? (
                    <img src={title} alt="Título" />
                  ) : (
                    <FaCloudUploadAlt />
                  )}

                  <input
                    id="title"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    {...register('title')}
                    onChange={onFile}
                  />
                </label>
              </File>
            </BoxFile>

            <BoxFile>
              <p>Banner de divulgação</p>
              <File>
                <label htmlFor="image">
                  {image ? (
                    <img src={image} alt="Card de divulgação" />
                  ) : (
                    <FaCloudUploadAlt />
                  )}

                  <input
                    id="image"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    {...register('image')}
                    onChange={onFile}
                  />
                </label>
              </File>
            </BoxFile>
            <Input
              label="Nome"
              type="text"
              {...register('name')}
              error={errors.name}
            />
            <Input
              label="Url do vídeo para divulgação"
              type="text"
              placeholder="Opcional"
              {...register('video')}
              error={errors.video}
            />
          </Box>

          <Box>
            <TextArea
              label="Descrição"
              {...register('description')}
              error={errors.description}
            />
            <Input
              label="Link de acesso para compra"
              type="text"
              {...register('link')}
              error={errors.link}
            />

            <Input
              label="Local"
              type="text"
              {...register('local')}
              error={errors.local}
            />

            <Input
              label="Data"
              type="text"
              {...register('date')}
              error={errors.date}
              placeholder="01 de novembro ..."
            />

            <TypeContainer>
              <Select
                label="Tipo"
                {...register('course_type_id')}
                error={errors.course_type_id}
                items={eventsType?.data ? eventsType.data : []}
              />
            </TypeContainer>

            <Radio
              label="Onde deseja exibir?"
              {...register('active')}
              error={errors.active}
              items={[
                {
                  externalId: 'prophetflix',
                  name: 'ProphetFlix',
                },
                {
                  externalId: 'event',
                  name: 'Eventos',
                },
              ]}
            />
          </Box>
        </Divisor>

        <Divisor width="10%">
          <Box>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Cadastrar
            </Button>
          </Box>
        </Divisor>
      </Form>
    </Container>
  );
};

export default CreateEvents;
