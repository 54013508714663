import React, { useState } from 'react';
import { RiInboxArchiveFill } from 'react-icons/ri';
import { Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../data/hooks/redux';
import Head from '../../../components/header';
import Input from '../../../components/input';
import Select from '../../../components/select';
import Switch from '../../../components/switch';
import TextArea from '../../../components/text-area';
import { Container, Header, Form, Divisor, Box, TypeContainer } from './styled';
import { createDiarySchema } from '../../../../validation/forms/calendar/create-calendar';
import { createCalendar } from '../../../../data/usecases/calendar/create-calendar';

const CreateCalendar: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({
    recurrence: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(createDiarySchema),
  });

  const onSubmit = async (value: any) => {
    setLoading(true);

    dispatch(
      createCalendar(
        {
          ...value,
          recurrence: steps.recurrence,
          frequency: value.frequency ? value.frequency : 'day',
          hour: `${value.hour}:00`,
        },
        history
      )
    );
    setLoading(false);
  };

  const onChange = async (event: any) => {
    setSteps((values) => ({
      ...values,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <Container>
      <Header>
        <Head title="Cadastro de Agenda" icon={<RiInboxArchiveFill />} />
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Divisor width="90%">
          <Box>
            <Input
              label="Título"
              type="text"
              {...register('title')}
              error={errors.title}
            />

            <TextArea
              label="Descrição"
              {...register('description')}
              error={errors.description}
            />

            <Input
              label="Local"
              type="text"
              {...register('local')}
              error={errors.local}
            />

            <Switch
              label="Essa agenda será recorrente?"
              {...register('recurrence')}
              error={errors.recurrence}
              onChange={onChange}
            />

            <TypeContainer>
              {steps.recurrence && (
                <Select
                  label="Selecione a frequência"
                  {...register('frequency')}
                  items={[
                    { externalId: 'day', name: 'Diária' },
                    { externalId: 'week', name: 'Semanal' },
                    { externalId: 'month', name: 'Mensal' },
                  ]}
                />
              )}
            </TypeContainer>

            <Input
              label="Dia"
              type="date"
              {...register('day')}
              error={errors.day}
            />

            <Input
              label="Hora"
              type="time"
              {...register('hour')}
              error={errors.hour}
              min="00:00"
              max="23:59"
            />
          </Box>
        </Divisor>

        <Divisor width="10%">
          <Box>
            <Button isLoading={loading} colorScheme="blue" type="submit">
              Cadastrar
            </Button>
          </Box>
        </Divisor>
      </Form>
    </Container>
  );
};

export default CreateCalendar;
