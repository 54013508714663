import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);

  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
  border-radius: 16px;

  @media ${({ theme }) => theme.devices.tablet} {
    padding: 0 10px;
  }
`;

export const Body = styled.div`
  width: 100%;
  height: auto;

  gap: 20px;
  padding: 20px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  overflow-y: scroll;
`;

export const Card = styled.div`
  width: 90%;
  height: 90%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  gap: 20px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};

  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.mobileL} {
    flex-direction: column;
    height: auto;

    align-items: center;
    justify-content: center;
  }
`;

export const CardBody = styled.div`
  width: 100%;
`;

export const Image = styled.img`
  width: 50%;
  max-width: 350px;
  height: auto;
  max-height: 350px;
  object-fit: contain;
  border-radius: 50%;

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 70%;
  }
`;

export const CardContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${({ theme }) => theme.devices.mobileL} {
    width: 100%;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 100px;

  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;

  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.styles.colors.white.normal};
`;

export const Badge = styled.div<{ color: 'red' | 'gray' }>`
  width: fit-content;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.styles.spacing.base};

  background-color: ${({ theme, color }) => theme.styles.colors[color].medium};
  color: ${({ theme }) => theme.styles.colors.white.medium};
`;
