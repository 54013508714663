import React, { useCallback, useState } from 'react';
import { FaTicketAlt, FaEye } from 'react-icons/fa';
import { HiIdentification } from 'react-icons/hi';
import { QrReader } from 'react-qr-reader';
import { Spinner, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import Head from '../../../components/header';
import Search from '../../../components/search';
import Paragraph from '../../../components/paragraph';
import * as Button from '../../../components/button';

import PassportComponent from './passport';
import DrawerComponent from './drawer';
import {
  Container,
  Grid,
  Card,
  QrCodeContainer,
  Table,
  TableItem,
  Badge,
  TableContainer,
} from './styled';

import { participants } from '../../../../infra/http/web-service';
import { getColorStatus, getStatus } from '../../../../utils/tickets';

export type Props = {
  match: {
    params: {
      id: string;
    };
  };
  location: {
    state: any;
  };
};

const details: React.FC<Props> = (props) => {
  const [value, setValue] = useState('Procurando...');
  const [isLoading, setLoading] = useState(true);
  const [isLoadingTickets, setLoadingTickets] = useState(false);
  const [isLoadingCheckIn, setLoadingCheckIn] = useState(false);
  const [ticket, setTicket] = useState({});
  const [allTicket, setAllTickets] = useState<any[]>([]);

  const {
    isOpen: isModelOpen,
    onOpen: onModalOpen,
    onClose: onModelClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearClose = () => {
    setValue('Procurando...');
    setLoading(true);
    setTicket({});
  };

  const clear = () => {
    setValue('Procurando...');
    setLoading(true);
    setTicket({});
    setAllTickets([]);
  };

  const listTicketByName = useCallback(async (name) => {
    setLoadingTickets(true);
    try {
      const { data } = await participants.listByName(
        name,
        props.match.params.id
      );

      if (!data.payload) {
        return;
      }

      setAllTickets(data.payload);
    } catch (error) {
      setAllTickets([]);
      toast.error('Este nome não tem ingressos cadastrados');
    }
    setLoadingTickets(false);
  }, []);

  const listTicketByDocument = useCallback(async (document) => {
    setLoadingTickets(true);
    try {
      const { data } = await participants.listByDocument(
        document,
        props.match.params.id
      );

      if (!data.payload) {
        return;
      }

      setAllTickets(data.payload);
    } catch (error) {
      setAllTickets([]);
      toast.error('Este documento não tem ingressos cadastrados');
    }
    setLoadingTickets(false);
  }, []);

  const listTicket = useCallback(
    async (participant: string | null, modal: boolean) => {
      try {
        const { data } = await participants.listById(
          participant || value,
          props.match.params.id
        );

        if (!data.payload) {
          return;
        }

        setTicket(data.payload);

        if (modal) {
          onModalOpen();
        } else {
          onOpen();
        }
      } catch (error) {
        toast.error('O ticket não foi encontrado na nossa busca');
      }
    },
    [value]
  );

  const checkInTicket = useCallback(async () => {
    setLoadingCheckIn(true);
    try {
      const { data } = await participants.checkIn(value, props.match.params.id);

      toast.success(data.message || 'Ticket liberado!');
      clear();
      onClose();
    } catch (error: any) {
      toast.error(
        error?.response?.message || 'Não conseguimos liberar o ticket'
      );
    }
    setLoadingCheckIn(false);
  }, [ticket]);

  return (
    <Container>
      <Head
        title={props.location?.state?.course?.name || 'Detalhes do evento'}
        icon={<FaTicketAlt />}
      />
      <Grid>
        <Card>
          <QrCodeContainer>
            <QrReader
              onResult={(result) => {
                if (result) {
                  setValue(result.getText());
                  setLoading(false);
                }
              }}
              constraints={{ facingMode: 'environment' }}
              videoStyle={{ height: '100%' }}
            />
          </QrCodeContainer>

          <Paragraph align="left" fontSize="default" label="Ticket:">
            {value}
          </Paragraph>

          <Button.Primary
            backgroundColor="blue"
            color="white"
            label="Buscar"
            type="button"
            disabled={isLoading}
            onClick={() => listTicket(null, false)}
          />

          <div style={{ margin: '20px 0' }} />

          <Paragraph align="left" fontSize="default" label="Ou também">
            Busque por cpf ou nome utilizando os campo abaixo
          </Paragraph>

          <Search
            placeholder="Digite o cpf sem formatação"
            onClick={listTicketByDocument}
          />

          <p style={{ margin: '10px 0' }}>Ou</p>

          <Search
            placeholder="Digite o nome do cliente"
            onClick={listTicketByName}
          />
        </Card>
        <Card>
          <Table>
            {!allTicket.length && <p>Pesquise pelos seus alunos</p>}

            {isLoadingTickets && <Spinner colorScheme="blue" />}

            {allTicket.map((ticketValue) => (
              <TableItem key={ticketValue?.participant?.external_id}>
                <TableContainer direction="column">
                  <Paragraph align="left" fontSize="small" label="Nome:">
                    {ticketValue?.participant?.name}
                  </Paragraph>
                  <Paragraph align="left" fontSize="small" label="E-mail:">
                    {ticketValue?.participant?.email}
                  </Paragraph>
                  <Paragraph align="left" fontSize="small" label="Status:">
                    <Badge
                      colorType={getColorStatus(
                        ticketValue.participant?.status
                      )}
                    >
                      {getStatus(ticketValue.participant?.status)}
                    </Badge>
                  </Paragraph>
                </TableContainer>

                <TableContainer direction="row">
                  <div className="button">
                    <Button.Primary
                      backgroundColor="gray"
                      color="white"
                      label=""
                      icon={<FaEye />}
                      type="button"
                      onClick={() => {
                        setValue(ticketValue?.participant?.external_id);
                        listTicket(
                          ticketValue?.participant?.external_id,
                          false
                        );
                      }}
                    />
                  </div>
                  <div className="button">
                    <Button.Primary
                      backgroundColor="orange"
                      color="white"
                      label=""
                      icon={<HiIdentification />}
                      type="button"
                      onClick={() => {
                        setValue(ticketValue?.participant?.external_id);
                        listTicket(ticketValue?.participant?.external_id, true);
                      }}
                    />
                  </div>
                </TableContainer>
              </TableItem>
            ))}
          </Table>
        </Card>
      </Grid>

      <DrawerComponent
        clear={clearClose}
        isOpen={isOpen}
        onClose={onClose}
        ticket={ticket}
        checkInTicket={checkInTicket}
        isLoading={isLoadingCheckIn}
      />

      <PassportComponent
        clear={clearClose}
        isOpen={isModelOpen}
        onClose={onModelClose}
        ticket={ticket}
        isLoading={isLoadingCheckIn}
      />
    </Container>
  );
};

export default details;
